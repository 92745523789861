<template>
  <div>
    <b-row
      class="content-header"
    >
      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="7"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="custom-header-title float-left pr-1 mb-0">
              {{ digitalForm.name }}
            </h2>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="orn-form-wrapper m-4">
        <div class="d-flex mb-1 justify-content-between">
          <div class="d-flex">
            <div class="d-form-logo_section" />
            <div
              class="urn_repository-company-details"
            >
              <div class="urn_repository-company-title">
                <h2>
                  <span>
                    富貴山莊
                  </span>
                  <br>
                  Mount Prajna Ltd.
                </h2>
              </div>
              <div class="urn_repository-company-tagline">
                <p> (Co. Reg. No. 200401183W)</p>
                <p>(GST Group Reg. No. M90364395L)</p>
              </div>
            </div>
          </div>
          <div class="d-form-no_section">
            <h5>
              {{ digitalForm.revision }}
            </h5>
          </div>
        </div>
        <div class="urn-repository-order_section">
          <div class="b-form-section-title my-2">
            <h3>
              Request form <span>需求表单</span>
            </h3>
          </div>
        </div>
        <div class="urn-form-particular_section">
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label-cols="12"
                label-cols-md="5"
                label-cols-lg="3"
                label="Customer Name 姓名*:"
                label-for="input-default"
              >
                <div
                  class="empty-input__text"
                ><p
                  emptyDiv="true"
                  data-text="Enter"
                /></div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label-cols="12"
                label-cols-md="5"
                label-cols-lg="3"
                label="NRIC 身份证号码*:"
                label-for="input-default"
              >
                <div
                  class="empty-input__text"
                ><p
                  emptyDiv="true"
                  data-text="Enter"
                /></div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label-cols="12"
                label-cols-md="5"
                label-cols-lg="3"
                label="Agent Name/Code 代理名字和商号码*: "
                label-for="input-default"
              >
                <div
                  class="empty-input__text"
                ><p
                  emptyDiv="true"
                  data-text="Enter"
                /></div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label-cols="12"
                label-cols-md="5"
                label-cols-lg="3"
                label="File No. 文件号码*: "
                label-for="input-default"
              >
                <div
                  class="empty-input__text"
                ><p
                  emptyDiv="true"
                  data-text="Enter"
                /></div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label-cols="12"
                label-cols-md="5"
                label-cols-lg="3"
                label="Niche/Pedestal No. 骨灰/神主牌位号码*: "
                label-for="input-default"
              >
                <div
                  class="empty-input__text"
                ><p
                  emptyDiv="true"
                  data-text="Enter"
                /></div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label-cols="12"
                label-cols-md="5"
                label-cols-lg="3"
                label="Date 日期*: "
                label-for="input-default"
              >
                <div
                  class="empty-input__text"
                ><p
                  emptyDiv="true"
                  data-text="Enter"
                /></div>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="d-form-an-ling-block preparing mt-2">
            <h3 class="pl-2">Request <span>需求</span>
            </h3>
            <div class="mt-1 pl-2">
              <div
                class="empty-input__textarea"
              ><p
                emptyDiv="true"
                data-text="Enter"
              /></div>
            </div>
          </div>
          <b-row class="mt-5">
            <b-col md="6">
              <p class="d-form-signature-title">Purchaser / Next of Kin
                <br>
                <span>购买者/至亲</span>
              </p>
              <div
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-modal.purchaser-sign-modal
                class="form-signature-box"
              >
                <div class="form-signature-content">
                  <span
                    v-if="!isPurchaserSign"
                    class="form-signature-text"
                  >
                    Sign here
                  </span>
                  <b-img
                    v-else
                    :src="purchaserSign"
                    alt="authorized-sign"
                  />
                </div>
              </div>
              <div class="signature-date-block">
                <p>Name 姓名: </p>
                <p>Date 日期: </p>
              </div>
            </b-col>
          </b-row>
          <div class="my-2">
            <p class="d-form-warning-text">
              Note: All request subject to approval at management’s sole discretion.
            </p>
            <p class="d-form-warning-text">
              注意：所有请求需经管理层全权酌情批准。
            </p>
          </div>
          <div class="d-form-border mt-4">
            <h5 class="d-form-office-use-title">
              For office use 公务用栏
            </h5>
            <b-row class="padding-20">
              <b-col
                md="4"
              >
                <p class="d-form-signature-title">
                  Received By 收件人员: </p>
                <div
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  v-b-modal.received-by-sign-modal
                  class="form-signature-box"
                >
                  <div class="form-signature-content">
                    <span
                      v-if="!receivedBySign"
                      class="form-signature-text"
                    >
                      Sign here
                    </span>
                    <b-img
                      v-else
                      :src="receivedBySignSrc"
                      alt="authorized-sign"
                    />
                  </div>
                </div>
                <div class="signature-date-block">
                  <p>Name 姓名: </p>
                  <p>Date 日期: </p>
                </div>
              </b-col>
              <b-col
                md="4"
              >
                <p class="d-form-signature-title">
                  Verified by 确认人员:</p>
                <div
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  v-b-modal.verified-sign-modal
                  class="form-signature-box"
                >
                  <div class="form-signature-content">
                    <span
                      v-if="!verifiedSign"
                      class="form-signature-text"
                    >
                      Sign here
                    </span>
                    <b-img
                      v-else
                      :src="verifiedSignSrc"
                      alt="authorized-sign"
                    />
                  </div>
                </div>
                <div class="signature-date-block">
                  <p>Name 姓名: </p>
                  <p>Date 日期: </p>
                </div>
              </b-col>
              <b-col
                md="4"
              >
                <p class="d-form-signature-title">
                  Approved By 批准人员:</p>
                <div
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  v-b-modal.approved-by-sign-modal
                  class="form-signature-box"
                >
                  <div class="form-signature-content">
                    <span
                      v-if="!approvedBySign"
                      class="form-signature-text"
                    >
                      Sign here
                    </span>
                    <b-img
                      v-else
                      :src="approvedBySignSrc"
                      alt="authorized-sign"
                    />
                  </div>
                </div>
                <div class="signature-date-block">
                  <p>Name 姓名: </p>
                  <p>Date 日期: </p>
                </div>
              </b-col>
              <b-col
                cols="12"
                class="mt-1"
              >
                <b-form-group
                  label-cols="4"
                  label-cols-lg="1"
                  label-for="input-default"
                >
                  <template #label>
                    <span>Remarks</span>
                    <br>
                    <span>备注</span>
                  </template>
                  <div
                    class="empty-input__text d-form-input-custom-height"
                  ><p
                    emptyDiv="true"
                    data-text="Enter"
                  /></div>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </b-card>
    <!-- purchaser signature modal -->
    <b-modal
      id="purchaser-sign-modal"
      ref="purchaser-sign-modal"
      modal-class="status-update-modal"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Purchaser's Signature"
      @ok="closePurchaserSignModal"
      @cancel="closePurchaserSignModal"
    >
      <div class="">
        <VueSignaturePad
          ref="signaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="options"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          @click="clearPurchaserSign()"
        >
          Clear
        </b-button>
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="savePurchaserSign()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ purchaser signature modal -->
    <!-- received by signature modal -->
    <b-modal
      id="received-by-sign-modal"
      ref="received-by-sign-modal"
      modal-class="status-update-modal"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Received By Signature"
      @ok="closeReceivedBySignModal"
      @cancel="closeReceivedBySignModal"
    >
      <div class="">
        <VueSignaturePad
          ref="signaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="options"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          @click="clear()"
        >
          Clear
        </b-button>
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveReceivedBySign()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ received by signature modal -->
    <!-- verified signature modal -->
    <b-modal
      id="verified-sign-modal"
      ref="verified-sign-modal"
      modal-class="status-update-modal sign-modal-adjust"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Verified By Signature"
      @ok="closeVerifiedSignModal"
      @cancel="closeVerifiedSignModal"
    >
      <div class="">
        <VueSignaturePad
          ref="signaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="options"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          @click="clearVerifiedSign()"
        >
          Clear
        </b-button>
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveVerifiedSign()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ verified signature modal -->
    <!-- approved-by signature modal -->
    <b-modal
      id="approved-by-sign-modal"
      ref="approved-by-sign-modal"
      modal-class="status-update-modal"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Approved By Signature"
      @ok="closeApprovedBySignModal"
      @cancel="closeApprovedBySignModal"
    >
      <div class="">
        <VueSignaturePad
          ref="signaturePad"
          class="border rounded"
          width="100%"
          height="260px"
          :options="options"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="flat-primary"
          @click="clear()"
        >
          Clear
        </b-button>
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveApprovedBySign()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ approved-by signature modal -->
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BFormGroup, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BImg,
  },
  directives: {
    Ripple,
  },
  props: {
    digitalForm: {
      type: Object,
      required: true,
    },
    topSpacing: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isSignature: false,
      isPurchaserSign: false,
      purchaserSign: '',
      receivedBySign: false,
      receivedBySignSrc: '',
      verifiedSign: false,
      verifiedSignSrc: '',
      approvedBySign: false,
      approvedBySignSrc: '',
      options: {
        penColor: '#000000',
        onBegin: () => { this.$refs.signaturePad.resizeCanvas() },
      },
    }
  },
  methods: {
    closePurchaserSignModal() {
      this.$root.$emit('bv::hide::modal', 'purchaser-sign-modal', '')
    },
    clearPurchaserSign() {
      this.$refs.signaturePad.clearSignature()
    },
    savePurchaserSign() {
      const { data } = this.$refs.signaturePad.saveSignature()
      this.purchaserSign = data
      if (data) {
        this.$root.$emit('bv::hide::modal', 'purchaser-sign-modal', '')
        this.isPurchaserSign = true
      }
    },
    closeReceivedBySignModal() {
      this.$root.$emit('bv::hide::modal', 'received-by-sign-modal', '')
    },
    clearReceivedBySign() {
      this.$refs.signaturePad.clearSignature()
    },
    saveReceivedBySign() {
      const { data } = this.$refs.signaturePad.saveSignature()
      this.receivedBySignSrc = data
      if (data) {
        this.$root.$emit('bv::hide::modal', 'received-by-sign-modal', '')
        this.receivedBySign = true
      }
    },
    closeVerifiedSignModal() {
      this.$root.$emit('bv::hide::modal', 'verified-sign-modal', '')
    },
    clearVerifiedSign() {
      this.$refs.signaturePad.clearSignature()
    },
    saveVerifiedSign() {
      const { data } = this.$refs.signaturePad.saveSignature()
      this.verifiedSignSrc = data
      if (data) {
        this.$root.$emit('bv::hide::modal', 'verified-sign-modal', '')
        this.verifiedSign = true
      }
    },
    closeApprovedBySignModal() {
      this.$root.$emit('bv::hide::modal', 'approved-by-sign-modal', '')
    },
    clearApprovedBySign() {
      this.$refs.signaturePad.clearSignature()
    },
    saveApprovedBySign() {
      const { data } = this.$refs.signaturePad.saveSignature()
      this.approvedBySignSrc = data
      if (data) {
        this.$root.$emit('bv::hide::modal', 'approved-by-sign-modal', '')
        this.approvedBySign = true
      }
    },
  },
}
</script>
