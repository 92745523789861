<template>
  <div>
    <b-row
      class="content-header white-background v-sticky-sidebar-container service_request_task"
      style="border-radius: 6px;"
    >
      <b-col
        class="content-header-right d-md-block"
        style="position: relative;"
        md="12"
        cols="12"
      >
        <!-- Table Container Card -->
        <b-card
          no-body
          class="mb-0"
        >
          <div class="orn-form-wrapper m-4">
            <div class="d-flex mb-1 justify-content-between">
              <div class="d-flex">
                <div class="d-form-logo_section" />
                <div
                  class="urn_repository-company-details"
                >
                  <div class="urn_repository-company-title">
                    <h2>
                      <span>
                        富貴山莊
                      </span>
                      <br>
                      Mount Prajna Ltd.
                    </h2>
                  </div>
                  <div class="urn_repository-company-tagline">
                    <p> (Co. Reg. No. 200401183W)</p>
                    <p>(GST Group Reg. No. M90364395L)</p>
                  </div>
                </div>
              </div>
              <div class="d-form-no_section">
                <h5>
                  {{ digitalForm.revision }}
                </h5>
              </div>
            </div>
            <div class="urn-repository-order_section">
              <div class="b-form-section-title">
                <h3>
                  Request form <span>需求表单</span>
                </h3>
              </div>
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label-cols="12"
                    label-cols-md="5"
                    label-cols-lg="3"
                    label="Customer Name 姓名*:"
                    label-for="input-default"
                  >
                    <div
                      v-if="currentCustomerNameType == 'input'"
                      class="empty-input__text bg-white"
                    >
                      <p>
                        {{ customerName || '-' }}
                      </p>
                    </div>
                    <div
                      v-else
                      style="background: #fff; border-bottom: 1px solid #000;"
                    >
                      <b-img
                        v-if="customerNameImage"
                        :src="customerNameImage"
                        class="w-100 signature-image"
                        alt="sign"
                      />
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <div class="">
                    <b-form-group
                      label-cols="12"
                      label-cols-md="5"
                      label-cols-lg="3"
                      label="NRIC 身份证号码*:"
                      label-for="input-default"
                    >
                      <div
                        class="empty-input__text bg-white"
                      >
                        <p>
                          {{ customerNRIC || '-' }}
                        </p>
                      </div>
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <div class="">
                    <b-form-group
                      label-cols="12"
                      label-cols-md="5"
                      label-cols-lg="3"
                      label="Agent Name/Code 代理名字和商号码*:"
                      label-for="input-default"
                    >
                      <div
                        class="empty-input__text bg-white"
                      >
                        <p>
                          {{ agentName || '-' }}
                        </p>
                      </div>
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <div class="">
                    <b-form-group
                      label-cols="12"
                      label-cols-md="5"
                      label-cols-lg="3"
                      label="File No. 文件号码*: "
                      label-for="input-default"
                    >
                      <div
                        class="empty-input__text bg-white"
                      >
                        <p>
                          {{ fileNo || '-' }}
                        </p>
                      </div>
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <div>
                    <b-form-group
                      label-cols="12"
                      label-cols-md="5"
                      label-cols-lg="3"
                      label="Niche/Pedestal No. 骨灰/神主牌位号码*: "
                      label-for="input-default"
                    >
                      <div
                        class="empty-input__text bg-white"
                      >
                        <p>
                          {{ nicheNo || '-' }}
                        </p>
                      </div>
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <div class="">
                    <b-form-group
                      label-cols="12"
                      label-cols-md="5"
                      label-cols-lg="3"
                      label="Date 日期*: "
                      label-for="input-default"
                    >
                      <div
                        class="empty-input__text bg-white"
                      >
                        <p>
                          {{ date || '-' }}
                        </p>
                      </div>
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
              <div class="d-form-an-ling-block preparing mt-1">
                <h3 class="pl-2">Request <span>需求</span>
                </h3>
                <div class="mt-1 pl-2">
                  <div
                    class="empty-input__textarea bg-white"
                  >
                    <p>
                      {{ requestRemarks || '-' }}
                    </p>
                  </div>
                </div>
              </div>

              <b-row class="mt-5">
                <b-col md="6">
                  <p class="d-form-signature-title">Purchaser / Next of Kin
                    <br>
                    <span>购买者/至亲</span>
                  </p>
                  <div
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="form-signature-box signed"
                  >
                    <div class="form-signature-content">
                      <span
                        v-if="!isPurchaserSign"
                        class="form-signature-text"
                      />
                      <b-img
                        v-else
                        :src="purchaserSign"
                        alt="sign"
                      />
                    </div>
                  </div>
                  <div class="signature-date-block">
                    <p>Name 姓名: {{ customerName }}</p>
                    <p v-if="isPurchaserSign">
                      Date 日期: {{ dateFormatWithTime(purchaserSignDate) }}
                    </p>
                    <p v-else>
                      Date 日期:
                    </p>
                  </div>
                </b-col>
              </b-row>

              <div class="my-1 margin-bottom-0">
                <p class="urn_repository-paragraph">
                  Note: All request subject to approval at management’s sole discretion.
                </p>
                <p class="urn_repository-paragraph">
                  注意：所有请求需经管理层全权酌情批准。
                </p>
              </div>
            </div>

            <div class="d-form-border mt-4">
              <h5 class="d-form-office-use-title">
                For office use 公务用栏
              </h5>
              <b-row class="padding-20">
                <b-col
                  md="4"
                >
                  <p class="d-form-signature-title">
                    Received By 收件人员: </p>
                  <div
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="form-signature-box signed"
                  >
                    <div class="form-signature-content">
                      <span
                        v-if="!receivedBySign"
                        class="form-signature-text"
                      >
                        Sign here
                      </span>
                      <b-img
                        v-else
                        :src="receivedBySignSrc"
                        alt="authorized-sign"
                      />
                    </div>
                  </div>
                  <div class="signature-date-block">
                    <p>Name 姓名: {{ receivedBySignName }}</p>
                    <p v-if="receivedBySign">
                      Date 日期: {{ dateFormatWithTime(receivedBySignDate) }}
                    </p>
                    <p v-else>
                      Date 日期:
                    </p>
                  </div>
                </b-col>
                <b-col
                  md="4"
                >
                  <p class="d-form-signature-title">
                    Verified by 确认人员:</p>
                  <div
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="form-signature-box signed"
                  >
                    <div class="form-signature-content">
                      <span
                        v-if="!verifiedSign"
                        class="form-signature-text"
                      >
                        Sign here
                      </span>
                      <b-img
                        v-else
                        :src="verifiedSignSrc"
                        alt="authorized-sign"
                      />
                    </div>
                  </div>
                  <div class="signature-date-block">
                    <p>Name 姓名: {{ verifiedSignName }}</p>
                    <p v-if="verifiedSign">
                      Date 日期: {{ dateFormatWithTime(verifiedSignDate) }}
                    </p>
                    <p v-else>
                      Date 日期:
                    </p>
                  </div>
                </b-col>
                <b-col
                  md="4"
                >
                  <p class="d-form-signature-title">
                    Approved By 批准人员:</p>
                  <div
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="form-signature-box signed"
                  >
                    <div class="form-signature-content">
                      <span
                        v-if="!approvedBySign"
                        class="form-signature-text"
                      >
                        Sign here
                      </span>
                      <b-img
                        v-else
                        :src="approvedBySignSrc"
                        alt="authorized-sign"
                      />
                    </div>
                  </div>
                  <div class="signature-date-block">
                    <p>Name 姓名: {{ approvedBySignName }}</p>
                    <p v-if="approvedBySign">
                      Date 日期: {{ dateFormatWithTime(approvedBySignDate) }}
                    </p>
                    <p v-else>
                      Date 日期:
                    </p>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  class="mt-1"
                >
                  <b-form-group
                    label-cols="4"
                    label-cols-lg="1"
                    label-for="input-default"
                  >
                    <template #label>
                      <span>Remarks</span>
                      <br>
                      <span>备注</span>
                    </template>
                    <div
                      class="empty-input__textarea bg-white"
                    >
                      <p>
                        {{ officeRemarks || '-' }}
                      </p>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BImg,
  },
  directives: {
    Ripple,
  },
  props: {
    digitalForm: {
      type: Object,
      required: true,
    },
    requestForm: {
      type: Object,
      required: true,
    },
    serviceRequest: {
      type: Object,
      required: true,
    },
    topSpacing: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isPurchaserSign: false,
      purchaserSign: '',
      purchaserSignDate: '',
      receivedBySign: false,
      receivedBySignSrc: '',
      receivedBySignDate: '',
      receivedBySignName: '',
      verifiedSign: false,
      verifiedSignSrc: '',
      verifiedSignDate: '',
      verifiedSignName: '',
      approvedBySign: false,
      approvedBySignSrc: '',
      approvedBySignDate: '',
      approvedBySignName: '',
      customerNameImageError: false,
      currentCustomerNameType: 'input',
      customerName: '',
      customerNameImage: '',
      customerNRIC: '',
      fileNo: '',
      nicheNo: '',
      date: '',
      requestRemarks: '',
      agentName: '',
      officeRemarks: '',
      existingReceivedBySignSrcObject: {},
      existingVerifiedSignSrcObject: {},
      existingApprovedBySignSrcObject: {},
      existingPurchaserSignObject: {},
      existingOfficeRemarksObject: {},
    }
  },
  created() {
    this.makeFormValues()
  },
  methods: {
    makeFormValues() {
      this.fileNo = this.serviceRequest.fileNo
      this.nicheNo = this.serviceRequest.serviceForm.deceasedNiche
      const customerNameObject = this.serviceRequest.formValues.find(o => o.key === 'customerName')
      if (customerNameObject) {
        if (customerNameObject.value.startsWith('data:image/')) {
          this.customerNameImage = customerNameObject.value
          this.currentCustomerNameType = 'canvas'
        } else {
          this.currentCustomerNameType = 'input'
          this.customerName = customerNameObject.value
        }
      }
      this.customerNRIC = this.serviceRequest.formValues.find(o => o.key === 'customerNRIC')?.value
      this.agentName = this.serviceRequest.formValues.find(o => o.key === 'agentName')?.value
      this.date = this.serviceRequest.formValues.find(o => o.key === 'date')?.value
      if (this.requestForm.values.length) {
        const requestRemarksObject = this.requestForm.values.find(o => o.key === 'requestRemarks')
        if (requestRemarksObject) {
          this.existingRequestRemarksObject = requestRemarksObject
          this.requestRemarks = requestRemarksObject.value
        }

        const officeRemarksObject = this.requestForm.values.find(o => o.key === 'officeRemarks')
        if (officeRemarksObject) {
          this.existingOfficeRemarksObject = officeRemarksObject
          this.officeRemarks = officeRemarksObject.value
        }
        const purchaserSignObject = this.requestForm.values.find(o => o.key === 'purchaserSign')
        if (purchaserSignObject) {
          this.existingPurchaserSignObject = purchaserSignObject
          this.purchaserSign = purchaserSignObject.value
          this.isPurchaserSign = true
          this.purchaserSignDate = purchaserSignObject.date
        }
        const verifiedSignSrcObject = this.requestForm.values.find(o => o.key === 'verifiedSignSrc')
        if (verifiedSignSrcObject) {
          this.existingVerifiedSignSrcObject = verifiedSignSrcObject
          this.verifiedSignSrc = verifiedSignSrcObject.value
          this.verifiedSign = true
          this.verifiedSignDate = verifiedSignSrcObject.date
          this.verifiedSignName = verifiedSignSrcObject.name
        }

        const receivedBySignSrcObject = this.requestForm.values.find(o => o.key === 'receivedBySignSrc')
        if (receivedBySignSrcObject) {
          this.existingReceivedBySignSrcObject = receivedBySignSrcObject
          this.receivedBySignSrc = receivedBySignSrcObject.value
          this.receivedBySign = true
          this.receivedBySignDate = receivedBySignSrcObject.date
          this.receivedBySignName = receivedBySignSrcObject.name
        }

        const approvedBySignSrcObject = this.requestForm.values.find(o => o.key === 'approvedBySignSrc')
        if (approvedBySignSrcObject) {
          this.existingApprovedBySignSrcObject = approvedBySignSrcObject
          this.approvedBySignSrc = approvedBySignSrcObject.value
          this.approvedBySign = true
          this.approvedBySignDate = approvedBySignSrcObject.date
          this.approvedBySignName = approvedBySignSrcObject.name
        }
      }
    },
  },
}
</script>

<style scoped>
@media print {
  .orn-form-wrapper{
     margin-top: 1rem !important;
     width: 1400px;
     height: 2000px;
  }
  input[type=checkbox], input[type=radio] {
      opacity: 1 !important;
  }
   .margin-bottom-0{
       margin-bottom: 0 !important;
  }
  .margin-top-0{
       margin-top: 0 !important;
  }
  .margin-bottom-1{
    margin-bottom: 1rem !important;
  }
  .margin-top-1{
       margin-top: 1rem !important;
  }
}
</style>
