<template>

  <div class="service__request sr__listPage">
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Forms for {{ serviceRequest.stringID }} - {{ serviceRequest.workFlow ? serviceRequest.workFlow.name : '' }}
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left booking_content_wrapper mb-2"
        cols="12"
        md="12"
      >
        <b-dropdown
          v-if="isMobile()"
          :text="currentTabTitle"
          variant="outline-primary"
          class="dropdown-icon-wrapper d-mob-custom-dropdown bg-white"
        >
          <template #button-content>
            <span class="w-100">{{ currentTabTitle }}</span>
            <feather-icon
              icon="ChevronDownIcon"
              class="ml-50"
              size="16"
            />
          </template>
          <b-dropdown-item
            v-for="(tab, index) in serviceRequestForms"
            :key="index"
            @click="changeRoute(tab._id)"
          >
            {{ tab.formID.name }}
          </b-dropdown-item>
        </b-dropdown>
        <b-tabs
          v-model="activeTab"
          pills
          class="booking__content"
          :nav-class="[isMobile() ? 'd-none' : '']"
        >
          <!-- Tab: Information -->
          <b-tab
            v-for="(tab, index) in serviceRequestForms"
            :key="index"
            lazy
            @click="changeRoute(tab._id)"
          >
            <template #title>
              <span class="d-none d-sm-inline">{{ tab.formID.name }}</span>
            </template>
            <urn-storage-request
              v-if="tab.formID.name == 'Request for Urn Repository For storage purpose only'"
              class="mt-2 pt-75"
              :digital-form="tab.formID"
              :top-spacing="topSpacing"
              :request-form.sync="serviceRequestForms[index]"
              :service-request.sync="serviceRequest"
            />
            <urn-repository-order
              v-if="tab.formID.name == 'Urn Repository Order'"
              class="mt-2 pt-75"
              :digital-form="tab.formID"
              :top-spacing="topSpacing"
              :request-form.sync="serviceRequestForms[index]"
              :service-request.sync="serviceRequest"
            />
            <ancestral-tablets-engraving
              v-if="tab.formID.name == 'Ancestral Tablets Engraving Notice'"
              class="mt-2 pt-75"
              :digital-form="tab.formID"
              :top-spacing="topSpacing"
              :request-form.sync="serviceRequestForms[index]"
              :service-request.sync="serviceRequest"
            />
            <pedestal-installation-order
              v-if="tab.formID.name == 'Pedestal Installation Order'"
              class="mt-2 pt-75"
              :digital-form="tab.formID"
              :top-spacing="topSpacing"
              :request-form.sync="serviceRequestForms[index]"
              :service-request.sync="serviceRequest"
            />
            <redemption-imported-urn
              v-if="tab.formID.name == 'Redemption of Imported Urn Form'"
              class="mt-2 pt-75"
              :digital-form="tab.formID"
              :top-spacing="topSpacing"
              :request-form.sync="serviceRequestForms[index]"
              :service-request.sync="serviceRequest"
              :service-request-tasks.sync="serviceRequestTasks"
              :task-id="serviceRequestForms[index].serviceRequestTask"
            />
            <redemption-of-ornament
              v-if="tab.formID.name == 'Redemption of Ornament Form'"
              class="mt-2 pt-75"
              :digital-form="tab.formID"
              :top-spacing="topSpacing"
              :request-form.sync="serviceRequestForms[index]"
              :service-request.sync="serviceRequest"
              :service-request-tasks.sync="serviceRequestTasks"
              :task-id="serviceRequestForms[index].serviceRequestTask"
            />
            <redemption-of-urn
              v-if="tab.formID.name == 'Redemption of Urn from Niche / Ancestral Tablet From Pedestal'"
              class="mt-2 pt-75"
              :digital-form="tab.formID"
              :top-spacing="topSpacing"
              :request-form.sync="serviceRequestForms[index]"
              :service-request.sync="serviceRequest"
            />
            <redemption-of-urn-return
              v-if="tab.formID.name == 'Redemption of Urn from Niche / Ancestral Tablet From Pedestal (Return to Company)'"
              class="mt-2 pt-75"
              :digital-form="tab.formID"
              :top-spacing="topSpacing"
              :request-form.sync="serviceRequestForms[index]"
              :service-request.sync="serviceRequest"
            />
            <urn-compartment-plaque-engraving
              v-if="tab.formID.name == 'Urn Compartment Plaque Engraving Notice'"
              class="mt-2 pt-75"
              :digital-form="tab.formID"
              :top-spacing="topSpacing"
              :request-form.sync="serviceRequestForms[index]"
              :service-request.sync="serviceRequest"
            />
            <anling-chai-ling
              v-if="tab.formID.name == 'Anling / Chai Ling Form'"
              class="mt-2 pt-75"
              :digital-form="tab.formID"
              :top-spacing="topSpacing"
              :request-form.sync="serviceRequestForms[index]"
              :service-request.sync="serviceRequest"
            />
            <customer-feedback
              v-if="tab.formID.name == 'Customer’s Comment Form'"
              class="mt-2 pt-75"
              :digital-form="tab.formID"
              :top-spacing="topSpacing"
              :request-form.sync="serviceRequestForms[index]"
              :service-request.sync="serviceRequest"
            />
            <request-form
              v-if="tab.formID.name == 'Request Form'"
              class="mt-2 pt-75"
              :digital-form="tab.formID"
              :top-spacing="topSpacing"
              :request-form.sync="serviceRequestForms[index]"
              :service-request.sync="serviceRequest"
            />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BTab, BTabs, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import UrnStorageRequest from './form-templates/UrnStorageRequest.vue'
import UrnRepositoryOrder from './form-templates/UrnRepositoryOrder.vue'
import AncestralTabletsEngraving from './form-templates/AncestralTabletsEngraving.vue'
import PedestalInstallationOrder from './form-templates/PedestalInstallationOrder.vue'
import RedemptionImportedUrn from './form-templates/RedemptionImportedUrn.vue'
import RedemptionOfOrnament from './form-templates/RedemptionOfOrnament.vue'
import RedemptionOfUrn from './form-templates/RedemptionOfUrn.vue'
import RedemptionOfUrnReturn from './form-templates/RedemptionOfUrnReturn.vue'
import UrnCompartmentPlaqueEngraving from './form-templates/UrnCompartmentPlaqueEngraving.vue'
import AnlingChaiLing from './form-templates/AnlingChaiLing.vue'
import CustomerFeedback from './form-templates/CustomerFeedback.vue'
import RequestForm from './form-templates/RequestForm.vue'

export default {
  components: {
    BRow,
    BCol,
    BTab,
    BTabs,
    BDropdown,
    BDropdownItem,

    UrnStorageRequest,
    UrnRepositoryOrder,
    AncestralTabletsEngraving,
    PedestalInstallationOrder,
    RedemptionImportedUrn,
    RedemptionOfOrnament,
    RedemptionOfUrn,
    RedemptionOfUrnReturn,
    UrnCompartmentPlaqueEngraving,
    AnlingChaiLing,
    CustomerFeedback,
    RequestForm,
  },
  data() {
    return {
      topSpacing: 135,
      activeTab: 0,
      currentTabTitle: '',
      serviceRequest: {},
      serviceRequestForms: [],
      serviceRequestTasks: [],
    }
  },
  watch: {
    $route() {
      // const param = this.$route.query.form
      // const currentTab = this.serviceRequestForms.find(o => o._id === param)
      // if (currentTab) {
      //   this.currentTabTitle = `${currentTab.formID.name}`
      // }
      this.getCurrentTab()
    },
  },
  beforeMount() {
    this.$http.get(`operation/service-requests/${this.$route.params.id}/form-view`)
      .then(response => {
        this.serviceRequest = response.data.data || {}
        this.serviceRequestForms = response.data.serviceRequestForms
        this.serviceRequestTasks = response.data.serviceRequestTasks || []
        if (this.serviceRequestForms.length) {
          this.getCurrentTab()
        }
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },

  methods: {
    changeRoute(param) {
      this.$router.replace({
        query: {
          form: param,
        },
        param: {},
      })
    },
    async getCurrentTab() {
      const param = this.$route.query.form
      if (param) {
        const currentTabIndex = this.serviceRequestForms.findIndex(o => o._id === param)
        if (currentTabIndex !== -1) {
          this.currentTabTitle = `${this.serviceRequestForms[currentTabIndex].formID.name}`
          await this.$nextTick()
          this.activeTab = currentTabIndex
        }
      }
    },
  },
}
</script>

<style scoped>
.rounded-number {
    height: 24px;
    width: 24px;
    background-color: #FFFFFF;
    border-radius: 50%;
    color:#104D9D;
}
</style>
